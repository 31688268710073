.treatment-summary {
  margin: 0 0 12px;
  padding: 0 0 12px;
  width: 100%;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  & + .treatment-summary {
    border-top: 1px solid var(--black-transparent-50);
    margin-top: 12px;
    padding-top: 24px;
  }

  & + .add-treatment {
    margin-top: 8px;
  }

  & > .input-wrapper {
    flex-grow: 0 !important;
    width: calc(100% - 125px);
  }

  label {
    margin: 0 0 4px;
  }

  p {
    margin: 0;
  }

  button {
    margin: 0 0 0 10px !important;
  }

  .applicationMethodName {
    text-transform: lowercase;
  }

  .treatment-actions {
    flex-shrink: 0;
    display: flex;
  }
}

.medicament-summary {
  counter-increment: medicament-summary;

  .treatment {
    white-space: normal;
    margin: 0 0 4px;

    &:before {
      content: counter(medicament-summary) ". ";
      font-weight: 700;
      margin: 0 3px 0 0;
      font-size: 1.2rem;
      line-height: unset;
      top: 0;
    }
  }

  & + .medicament-summary {
    margin-top: 12px;
  }

  b {
    margin: 0 5px 0 0;
  }

  .truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
