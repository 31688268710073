.input-wrapper {
  &.checkbox {
    margin-bottom: 12px;
  }
}

.input-checkbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  outline: none;

  [type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  [type="checkbox"] + label {
    cursor: pointer;
    display: block;
    position: relative;
    margin: 0;
    color: #999;
    outline: none;
    transition: 0.1s ease;
  }

  [type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: transparent;
    border: 1px solid var(--black-transparent-300);
    margin: 0 10px 0 0;
    border-radius: 6px;
    position: relative;
    top: 7px;
  }

  [type="checkbox"] + label:after {
    content: "\f12c";
    font-family: icons;
    font-size: 2rem;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: 0.1s;
    opacity: 0;
    visibility: hidden;
  }

  [type="checkbox"]:hover + label:before {
    border-color: var(--black-transparent-300);
    outline: 4px solid var(--black-transparent-100);
  }

  [type="checkbox"]:checked + label {
    color: var(--black-700);
    font-weight: 700;
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    visibility: visible;
  }

  [type="checkbox"]:disabled + label {
    pointer-events: none;
  }
}
