.medicament-form {
  position: relative;
  background: var(--black-transparent-35);
  padding: 2px 24px;
  border-radius: 20px;
  margin: 0 0 24px;
  border: 1px solid var(--black-transparent-35);

  h3 {
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    opacity: 0.4;
  }
}

.measure-ids {
  position: absolute;
  background: #fff;
  width: 140px;
  padding: 16px 8px 16px 16px;
  border-radius: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  bottom: calc(100% + 8px);
  right: 0;

  & > div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    span {
      transition: 0.2s ease;
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(
        to right,
        var(--black-transparent-50),
        transparent
      );

      span {
        transform: translateX(6px);
        color: var(--primary-400);
      }
    }
  }
}
