.card {
  width: calc(33% - 12px);
  background: var(--white);
  box-shadow: var(--shadow-200);
  border-radius: 20px;
  padding: 4px 24px 24px;
  margin: 0 0 24px;
  transition: 0.2s ease;

  &.wide {
    width: 66%;
  }

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin: 18px 0 24px;
  }
}

@media screen and (max-width: 1024px) {
  .card {
    width: 100%;

    &.wide {
      width: 100%;
    }
  }
}
