.ward {
  transition: 0.2s ease;

  .ward-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 0 0 -16px;
    padding: 4px 8px 4px 16px;
    width: calc(100% + 16px);
    transition: 0.05s ease;

    &:hover {
      background: var(--black-transparent-50);
    }

    .delete-ward {
      padding: 6px 6px;
      margin: 0 0 0 20px;
      font-size: 2rem;
      line-height: 1;
    }
  }

  .ward-name {
    font-size: 1.8rem;
    margin: 0 16px 0 0;
    padding: 0;
    flex-grow: 1;
    transition: 0.15s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before {
      content: counter(wards) ".";
      counter-increment: wards;
      margin: 0 8px 0 0;
      color: var(--black-transparent-300);
    }
  }

  .ward-content {
    margin: 16px 0 24px;
  }
}

@media screen and (max-width: 1024px) {
  .ward {
    .ward-header {
      width: 100%;
      margin: 0;
      padding: 8px 0;
    }
  }
}
