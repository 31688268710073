.modal {
  .ask-for-advice {
    .advice.draft {
      opacity: 1;
      background: var(--primary-50);
      border: 1px solid var(--black-transparent-35);
    }

    .advice-header h2,
    .advice-actions h2 {
      font-size: 3rem;
      margin: 0 0 -16px;
    }

    .questions {
      .questions-list {
        padding: 24px 0 0;
      }

      .question {
        background: var(--primary-50);
        border: 1px solid var(--black-transparent-35);
      }

      .question.answered,
      .question.answered h3,
      .question.answered .answer {
        background: var(--white);
        border: none;
      }
    }

    .medicament-wrapper + .medicament-wrapper {
      border-left-color: var(--black-transparent-50);
    }

    .medicament {
      background: var(--white);
    }
  }
}
