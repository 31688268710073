.modal.dialog {
  height: auto;
  min-height: auto;
  overflow: visible;
  z-index: 15010;

  .modal-title {
    text-align: left;
    line-height: 1.1;
    max-width: 83.333%;
    margin: 0 0 64px;
  }

  .dialog-message {
    margin: -40px 0 0;
    white-space: pre-wrap;
  }

  .dialog-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 24px 0 0;
  }
}

.dialog-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 200vw;
  height: 200vh;
  z-index: 5000;
  background: var(--black-transparent-300);
  transform: translateX(-50vw) translateY(-50vh);
  transition: 0.3s ease;
}

@media screen and (max-width: 768px) {
  .modal.dialog {
    width: calc(100% - 32px);
    left: 16px;
    bottom: -20px;
    border-radius: 20px;

    .modal-title {
    }
  }
}
