main {
  flex-grow: 1;
  transition: 0.5s ease;
  padding: 0 32px 40px 40px;

  .content,
  .content > div {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  main {
    width: 100%;
    margin: 48px 0 32px;
    padding: 0;
  }
}
