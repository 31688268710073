.advice-summary:before {
  content: "";
  position: absolute;
  left: -24px;
  top: -12px;
  width: calc(100% + 48px);
  height: calc(100% + 24px);
  background: var(--black-transparent-35);
  z-index: -1;
  opacity: 0;
}

.advice-summary:hover:before {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .advice-summary:before {
    left: -16px;
    width: calc(100% + 32px);
  }
}
