.filters-statistics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 32px;

  .input-wrapper {
    width: auto;
    min-width: 240px;
    margin: 0 16px 0 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .filters-statistics {
    .input-wrapper {
      min-width: 200px;
    }
  }
}

@media screen and (max-width: 600px) {
  .filters-statistics {
    flex-flow: column nowrap;
    width: 100%;

    .input-wrapper {
      width: 100%;
      margin: 0 0 24px !important;
    }
  }
}
