.chart-external {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  .chart-wrapper {
    display: block;
    position: relative;
    width: 33%;
    flex-shrink: 0;
    height: 160px;
    margin: 0 20px 0 0;
  }

  canvas {
    margin: 0;
  }

  .htmlLegend ul {
    flex-flow: column nowrap !important;
    font-size: 1.4rem;

    li {
      margin: 0 0 4px !important;

      span {
        border-radius: 50% !important;
        width: 14px !important;
        height: 14px !important;
        flex-shrink: 0;
      }
    }
  }
}
