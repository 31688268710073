.workflow-builder {
  .tabs {
    border-bottom: 1px solid var(--primary-100);
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 3rem;
      background: linear-gradient(to bottom, var(--primary-50), transparent);
    }

    .tabs-header {
      button.active {
        padding: 12px 16px;
        pointer-events: none !important;
      }
    }
  }
}
