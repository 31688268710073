.patients-and-advices {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;

  .table {
    flex-grow: 1;
  }

  .card {
    .advice-summary div {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h2 {
      font-size: 2.2rem;
      margin: 24px 0 8px;

      &:before {
        content: "Patient";
        display: block;
        color: #aaa;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        font-size: 1.3rem;
        line-height: 0.5;
      }
    }

    .advice-summary {
      position: relative;
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }

      &.dimmed {
        color: #bbb;

        .tag {
          opacity: 0.7;
        }
      }
    }

    .disease {
      font-weight: 700;
      font-size: 1.8rem;
    }
  }
}

.tags {
  margin: 10px 0 0;
  display: flex;
  flex-flow: row wrap;

  .tag {
    font-weight: 600;
    background: var(--black-transparent-50);
    color: var(--black-200);
    width: auto !important;
    padding: 2px 10px;
    border-radius: 8px;
    margin: 0 6px 6px 0;
    font-size: 1.2rem;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    display: inline-block;

    &.emphasized {
      background: #f9a100;
      color: var(--white);
    }
  }
}

[data-lang="bg"] .patients-and-advices .card h2:before {
  content: "Пациент";
}

.patients-and-advices .card .day {
  color: #aaa;
}

.treatment:before,
.day:before {
  content: "\2022";
  font-size: 2rem;
  line-height: 1;
  position: relative;
  top: 1px;
  margin: 0 4px 0 0;
  opacity: 0.3;
}

.sidebar {
  margin-left: 32px;
  width: 350px;
  flex-shrink: 0;

  .card-wrapper {
    background: var(--white);
    box-shadow: var(--shadow-200);
    padding: 20px 24px;
    border-radius: 20px;
    margin: 0 0 24px;

    &.empty {
      & > h2 {
        padding: 0;
        border: none;
      }
    }

    & > h2 {
      margin: 0;
      padding: 0 0 12px;
      font-size: 1.8rem;
      border-bottom: 1px solid #eee;
      text-align: right;

      .count {
        color: var(--black-transparent-300);
        margin: 0 0 0 6px;
      }
    }
  }

  .card-patient,
  .file {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    padding: 0;
    border: none;
    width: 100% !important;
    border-bottom: 1px dashed var(--black-transparent-100);
    border-radius: 0;
    padding: 10px 0;
    margin: 0;

    &:last-child,
    &:last-child {
      border: none;
    }
  }

  .card-patient {
    cursor: pointer;

    .info {
      overflow: hidden;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      font-size: 2rem;
    }

    .advice-summary {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      flex-grow: 1;
      pointer-events: none;
    }

    .buttons {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 0 0 auto;

      button {
        padding: 4px 6px 3px 12px;
        font-size: 1.5rem;
        margin: 0 0 0 auto;

        .icon {
          font-size: 1.8rem;
          line-height: 0.5;
          margin: 0;
          padding: 0;
        }
      }

      button + button {
        margin-top: 6px;
      }
    }
  }

  .follow-up .card-patient button {
    padding: 4px 10px 3px 11px;
  }

  .file {
    button {
      padding: 4px 10px;
      font-size: 1.5rem;
      margin: 0 0 0 auto;

      .icon {
        font-size: 1.8rem;
        line-height: 0.5;
        margin: 0;
        padding: 0;
      }
    }
  }

  .card-wrapper.latest-files {
    margin-top: 12px;
  }

  .links-all {
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0;
    font-size: 1.4rem;
    color: #aaa;

    a {
      color: #aaa;
      text-decoration: none;
      flex-grow: 1;
      border-right: 1px solid #ddd;
      text-align: center;

      &:last-child {
        border: none;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .app:not(.sidebar-collapsed) {
    .patients-and-advices {
      .table {
        .search {
          display: none;
        }

        .layout-toggle {
          margin: 0 16px 0 0;
        }
      }
    }
  }

  .advices-toggle {
    display: block !important;
    order: 2;
    margin: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .patients-and-advices {
    flex-flow: row wrap;

    .table {
      width: 100%;
    }

    .filters {
      width: 100%;
      overflow-x: auto;

      .filter-groupd {
        overflow: visible;
        width: unset;
      }
    }
  }

  .sidebar {
    width: 100%;
    margin: 24px 0 0;
  }
}
