.form-secondary {
  max-width: 500px;
  background: var(--primary-100);
  padding: 32px;
  border: 1px solid var(--black-transparent-50);
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 96px);
  transition: 0.3s ease;
  z-index: 50;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);

  &.toggled {
    transform: translateY(-44px);
  }

  &.form-ward {
    right: 20px;
    left: auto;
  }

  &.form-head-ward {
    left: auto;
    right: 50px;
  }
}

.form-secondary-background {
  background: var(--white-transparent-700);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  z-index: 5;

  &.toggled {
    opacity: 1;
    visibility: visible;
  }

  &.form-head-background {
    z-index: 10;
  }
}

.created-head {
  button {
    float: right;
    color: var(--black-transparent-600);
  }
  .created-head-info {
    transition: 0.2s ease;
    outline: 5px solid transparent;
    outline-offset: 5px;
    border-radius: 10px;

    &.highlight {
      outline-color: var(--black-transparent-100);
    }
  }

  span {
    display: block;
  }
}

.previous-head {
  background: var(--primary-100);
  padding: 20px 20px 12px;
  margin: 0px 0 24px;
  border-radius: 10px;

  h3 {
    font-size: 1.6rem;
    letter-spacing: 0.3px;
  }

  label[for="previous-head-action-doctor"] {
    margin-right: 20px;
  }
}

.form-ward {
  .previous-head {
    background: var(--white);
  }
}

@media screen and (max-width: 1024px) {
  .form-secondary,
  .form-secondary.form-ward {
    position: absolute;
    top: auto;
    bottom: -44px;
    left: 0;
    right: 0;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    padding: 20px;

    &.toggled {
      transform: translateY(-24px);
    }
  }

  .form-secondary.form-head-ward {
    right: -10px;
  }
}
