.my-hospital {
  position: relative;
  min-height: 100vh;

  .hospital-info {
    margin: 0 0 32px;
  }

  p {
    margin: 0 0 8px;
  }
}

@media screen and (max-width: 768px) {
  .my-hospital {
    min-height: 94vh;
  }
}
