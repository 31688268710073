.cards-are-single-advices {
  .card {
    padding-bottom: 12px;

    h2 + .disease {
      margin-top: 0;
    }

    .disease {
      margin: 16px 0 8px;
    }

    .advice-summary:before {
      display: none;
    }
  }

  .cards.layout-list .advice-summary {
    padding: 0 0 16px;
  }
}
