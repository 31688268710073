.custom-edge {
  background-color: var(--primary-25);
  padding: 4px 10px;
  border: 1px solid var(--primary-200);
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  button.open-advice {
    position: absolute;
    left: calc(100% + 2px);
    top: 50%;
    padding: 2px 6px !important;
    transform: translateY(-55%);
    background: transparent;
    border: none;
    color: var(--black-200);
    font-size: 1.2rem;
  }

  &:hover {
    background-color: var(--primary-50);
  }
}
