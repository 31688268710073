.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: var(--white);

  &:before {
    content: "";
    background: url(../../../public/images/logo.svg);
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 4000px;
    z-index: -1;
    background-position: center;
    opacity: 0.1;
    filter: blur(30px);
  }

  .logo {
    margin: 20px 0 0;

    .text {
      font-size: 3.2rem;
    }

    .image {
      margin: 0 8px 0 0;
    }
  }

  .loader-animation {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #ccc;
  }

  .loader-animation:before,
  .loader-animation:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .loader-animation:after {
    color: var(--primary-400);
    transform: rotateY(70deg);
    animation-delay: 0.4s;
  }

  .loader-message {
    margin: 16px 0 0;
    font-weight: 400;
    font-size: 2rem;
    opacity: 0.6;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
