.medicament-simple {
  margin: 8px 0 0;
  width: 100%;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  & + .medicament-simple {
    border-top: 1px dashed var(--black-transparent-100);
    padding-top: 20px;
    margin-top: 12px;
  }

  .flex-input-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    & > span {
      white-space: nowrap;
      font-size: 1.5rem;
      margin: 0 0 6px 8px;
    }
  }

  .input-wrapper {
    margin: 0 0 6px 8px !important;
    flex-grow: 0 !important;
    flex-shrink: 0;

    &.iw-name {
      width: 12rem;
      flex-grow: 1 !important;
    }

    &.iw-dose {
      width: 6rem;
    }

    &.iw-measure {
      width: 5rem;
      margin-right: 8px !important;
    }

    &.iw-times {
      width: 3.5rem;
    }

    &.iw-per {
      width: 5rem;
    }

    &.iw-duration {
      width: 4.5rem;
    }

    &.iw-quantity {
      width: 4rem;
    }
  }

  label {
    display: none;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .timesPer {
    margin: 0 0 0 8px;
    font-size: 1.4rem;
  }

  .modal-close {
    position: static;
    font-size: 2rem;
    padding: 8px;
    margin: 0 0 6px auto;
    background: var(--primary-50);
    border: 1px solid var(--black-transparent-50);
  }
}
