:root {
  --primary-25: #fff9fa;
  --primary-50: #fff1f2;
  --primary-100: #ffe3e5;
  --primary-200: #ffccd3;
  --primary-300: #ffa2ae;
  --primary-400: #ff6d84;
  --primary-500: #f82249;
  --primary-600: #e61847;
  --primary-700: #c30d3c;
  --primary-800: #a30e39;
  --primary-900: #8b1037;
  --primary-transparent-50: rgba(248, 34, 73, 0.05);
  --primary-transparent-100: rgba(248, 34, 73, 0.127);
  --primary-transparent-200: rgba(248, 34, 73, 0.247);

  --white: #ffffff;
  --white-transparent-50: rgba(255, 255, 255, 0.05);
  --white-transparent-100: rgba(255, 255, 255, 0.1);
  --white-transparent-200: rgba(255, 255, 255, 0.2);
  --white-transparent-300: rgba(255, 255, 255, 0.3);
  --white-transparent-400: rgba(255, 255, 255, 0.4);
  --white-transparent-500: rgba(255, 255, 255, 0.5);
  --white-transparent-600: rgba(255, 255, 255, 0.6);
  --white-transparent-700: rgba(255, 255, 255, 0.7);
  --white-transparent-800: rgba(255, 255, 255, 0.8);
  --white-transparent-900: rgba(255, 255, 255, 0.9);

  --black-transparent-35: rgba(0, 0, 0, 0.035);
  --black-transparent-50: rgba(0, 0, 0, 0.05);
  --black-transparent-100: rgba(0, 0, 0, 0.1);
  --black-transparent-200: rgba(0, 0, 0, 0.2);
  --black-transparent-300: rgba(0, 0, 0, 0.3);
  --black-transparent-400: rgba(0, 0, 0, 0.4);
  --black-transparent-500: rgba(0, 0, 0, 0.5);
  --black-transparent-600: rgba(0, 0, 0, 0.6);
  --black-transparent-700: rgba(0, 0, 0, 0.7);
  --black-transparent-800: rgba(0, 0, 0, 0.8);
  --black-transparent-900: rgba(0, 0, 0, 0.9);

  --black: #000;
  --black-900: #111;
  --black-800: #222;
  --black-700: #333;
  --black-600: #444;
  --black-500: #555;
  --black-400: #666;
  --black-300: #777;
  --black-200: #888;
  --black-100: #999;

  --gray-400: #9b9da7;

  --shadow-200: 1px 1px 5px 1px rgb(202 206 221 / 20%);
  --shadow-300: 1px 1px 5px 1px rgb(202 206 221 / 30%);
  --shadow-400: 1px 1px 5px 1px rgb(202 206 221 / 40%);
}
