.counter {
  .count {
    font-size: 5.4rem;
    line-height: 1;
    margin: -16px 0 8px 0;
    font-weight: 500;
  }

  .duration {
    opacity: 0.6;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1024px) {
  .counter {
    .count {
      font-size: 4rem;
    }
  }
}
