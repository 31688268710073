.advice-consultation.ask-for-advice {
  h1 {
    line-height: 1.3;
    margin-top: 3px;
  }
}

.advice.no-antibiotics-needed {
  .treatment-title {
    font-size: 2rem;
    line-height: 2.8rem;
    width: 100%;
    font-weight: 600;
  }
}

.advice {
  .advice-header {
    border-bottom: 2px solid var(--black-transparent-35);
    padding-bottom: 24px;
    margin-bottom: -4px;
  }

  h2:not(.advice-header-title) {
    margin: 28px 0 8px;
    text-wrap: balance;
  }

  .treatment-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
    width: 100%;
  }

  h3 {
    color: #999;
  }

  b {
    margin: 0 8px 0 0;
  }

  ul {
    padding: 0 0 0 32px;
    margin: 0 0 12px;
  }

  .title-advice {
    display: flex;

    span {
      label {
        margin: 16px 0 8px;
      }
    }

    &.has-checkbox h2 {
      opacity: 1;
      font-size: 2.4rem;
      text-transform: none;
      letter-spacing: 0;
      top: -2px;
      position: relative;
      background: #fff;
      padding: 0 24px 0 8px;
    }

    &.has-checkbox label:before {
      outline: 4px solid var(--primary-100);
      animation: expandAndContract 4s infinite;
    }

    @keyframes expandAndContract {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(1.2);
        outline-color: transparent;
        outline-color: var(--primary-200);
      }
      30% {
      }
      60% {
        transform: scale(1);
        outline: 4px solid var(--primary-100);
      }
      100% {
        transform: scale(1);
      }
    }

    &.has-checkbox {
      position: relative;
    }

    &.has-checkbox:after {
      content: "";
      position: absolute;
      bottom: 17px;
      left: 40px;
      width: calc(100% - 40px);
      height: 16px;
      background: #eee;
      z-index: -1;
    }
  }

  h2:empty {
    display: none;
  }

  .medicament-wrapper + .medicament-wrapper {
    padding-top: 16px;
    padding-left: 24px;
    border-left: 2px solid var(--black-transparent-35);
  }

  .medicament {
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 700;
    display: block;
    margin: 0 0 16px;
    background: var(--black-transparent-35);
    padding: 6px 16px;
    border-radius: 8px;
    display: inline-block;
  }

  .medicament-info {
    display: block;
    margin: 0 0 12px;

    b {
      margin: 0 8px 0 0;
    }
  }

  a.leaflet,
  button.leaflet {
    opacity: 0.6;
    text-decoration: none;
    border-bottom: 1px solid var(--black-transparent-100);
    padding: 0;
    background: transparent;
    display: inline;
    color: #333;
    border-radius: 0;
  }

  h4 {
    margin: 0;
  }

  .plus {
    margin: 0 10px 0 0;
    font-size: 3.8rem;
    line-height: 0;
    font-weight: 400;
    position: relative;
    top: 7px;
  }

  .interactions {
    white-space: pre-wrap;
  }

  .advice-list {
    ol {
      padding: 0;
      counter-reset: survey;

      li {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        counter-increment: survey;
        position: relative;
        margin: 0 0 6px;
        line-height: 1.4;

        & > span {
          background: var(--white);
          max-width: 75%;
          flex-shrink: 1;
          flex-grow: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            padding: 0 12px 0 0;
          }

          &:first-child:before {
            content: counter(survey) ".";
            margin: 0 8px 0 0;
            font-weight: 700;
          }

          &:nth-child(2) {
            padding: 0 0 0 12px;
            text-align: right;
            flex-grow: 0;
            flex-shrink: 1;
          }

          &.new-treatment {
            flex-flow: column nowrap;
            align-items: flex-end;
            justify-content: flex-end;
          }
        }
      }
    }

    &.items-dashed {
      li {
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          z-index: -1;
          height: 1px;
          background-image: linear-gradient(
            90deg,
            var(--primary-200),
            var(--primary-200) 50%,
            transparent 50%,
            transparent 100%
          );
          background-size: 12px 1px;
        }
      }
    }

    &.rejection {
      span {
        max-width: 100%;
        padding: 0 !important;
      }
    }
  }
}

.treatment-wrapper + .treatment-wrapper {
  border-top: 2px solid var(--black-transparent-35);
  margin: 32px 0 0;
}

.treatment-info {
  margin: -4px 0 24px;
  max-width: 75%;
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .advice {
    .advice-list {
      ol li {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 16px;
        display: block;

        &:before {
          opacity: 0;
        }

        span {
          display: inline;
          max-width: 100%;
          padding: 0 !important;
          margin: 0;
          text-align: left;

          &:nth-child(2) {
            margin: 0;
            text-align: left;
            display: block;

            &:nth-child(2):before {
              content: "\2014";
              margin: 0 6px 0 0;
              opacity: 0.3;
            }
          }
        }
      }
    }

    .medicament-wrapper + .medicament-wrapper {
      padding-left: 16px;
    }
  }
}
