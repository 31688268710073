.vanilla-jsoneditor-react {
  display: flex;
  flex: 1 1;
  border-radius: 4px;
  overflow: hidden;

  .jse-menu {
    background: var(--primary-500) !important;
  }
}
