.layout-toggle {
  margin: 0 0 0 16px;
}

.filters {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: -16px 0 24px;

  div:empty {
    display: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .filter-group {
    margin: 0 0 16px;
    padding: 0 0 4px;
    width: 100%;
    flex-flow: row nowrap;
    overflow-y: auto;

    > div {
      max-width: 130px;
      min-width: 110px;
    }

    > div + div:not(.empty) {
      margin: 0 0 0 16px;
    }

    > div > div:not(.sortable) {
      flex-grow: 1;
    }

    &:first-child {
      display: none;
    }

    input {
      font-size: 1.4rem;
      padding: 6px 10px;
      border-color: var(--black-transparent-100);
      outline-width: 3px;
    }

    .sortable {
      font-weight: 600;
      margin: 0 0 6px;
      cursor: pointer;
      white-space: nowrap;
      width: 100%;

      & > span {
        width: 100%;

        .sorted {
          font-size: 1rem;
          margin: 0 0 0 8px;
          background: #f9a100;
          padding: 4px 7px;
          border-radius: 20px;
          color: var(--white);
          position: relative;
          top: -3px;
        }
      }

      .icon:before {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1;
        margin: 0;
        position: relative;
        top: 1px;
      }
    }
  }
}

[data-lang="bg"] .hospital-management .cityName:after {
  content: "Контакти" !important;
  width: 70px !important;
}

.hospital-management {
  .contactPerson,
  .phone,
  .email {
    padding: 0 0 0 12px;
    border-left: 1px dashed var(--black-transparent-100);
    order: 2;
  }

  .cityName:after {
    content: "Contact";
    display: block;
    font-weight: 600;
    background: var(--primary-50);
    width: auto !important;
    padding: 2px 10px;
    border-radius: 8px;
    margin: 12px 0;
    font-size: 1.3rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    width: 62px !important;
  }

  .cards.layout-list {
    .cityName:after {
      display: none;
    }
  }
}

.clear-filters {
  width: 100%;
  margin: -24px 0 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  button {
    padding: 5px 11px 5px 10px;
    font-size: 1.4rem !important;
    letter-spacing: 0.1px;
    margin: 0 8px 8px 0;
  }
}

.cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: calc(100% + 20px);

  .card {
    width: 250px;
    margin: 0 20px 24px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0 24px 74px 24px;
    position: relative;
    border: 1px solid var(--black-transparent-50);
    box-shadow: rgb(165 149 149 / 12%) 0px 4px 8px;

    &:hover {
      transform: translateY(-2px);
      cursor: pointer;
      border-color: #ddd;
    }

    &:focus {
      box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.2) inset;
      transform: translateY(3px);
    }

    &.inactive {
      opacity: 0.5;
      box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.2) inset;

      h3:before {
        content: "Deactivated";
        display: block;
        text-transform: uppercase;
        font-size: 1.3rem;
        letter-spacing: 0.1px;
        color: #aaa;
        line-height: 1.2;
      }
    }

    .hospitalName,
    .hospitalWardName {
      padding: 0 0 0 12px;
      border-left: 1px dashed var(--black-transparent-100);
      order: 2;
    }

    .roleName {
      font-weight: 600;
      background: var(--primary-50);
      width: auto !important;
      padding: 2px 10px;
      border-radius: 8px;
      margin: 14px 0;
      font-size: 1.3rem;
      letter-spacing: 0.01em;
    }

    .active {
      position: absolute;
      bottom: 26px;
      left: calc(50% - 6px);
    }

    div,
    h3 {
      width: 100%;
    }

    div:empty {
      display: none;
    }

    h3 {
      margin-bottom: 12px;
    }

    .description {
      margin: 8px 0 24px;
      font-size: 1.5rem;
      opacity: 0.7;
    }

    .fileType,
    .fileSize,
    .download,
    .active,
    .update,
    .delete {
      width: auto !important;
    }

    .fileSize:before {
      content: "\00B7";
      margin: 0 6px;
    }

    .workflowVersion,
    .draftVersion {
      font-weight: 700;
      font-feature-settings: "lnum";
      font-variant: lining-nums;
      background: transparent;
      text-transform: lowercase;
      padding: 0;
      color: #aaa;
      font-size: 1.3rem;

      span {
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 0.3px;
      }
    }

    .workflowVersion {
      margin: 4px 0 0;
    }

    .draftVersion {
      color: #f9a100;
    }

    .update,
    .download {
      position: absolute;
      bottom: 20px;
      left: 24px;

      button,
      a {
        padding: 4px 11px 3px 10px;
      }
    }

    div.delete {
      position: absolute;
      right: 24px;
      bottom: 20px;

      button {
        padding: 4px 9px;
      }
    }

    button,
    a {
      margin: 32px 0 0;
      font-size: 1.5rem;
      min-height: 34px;

      .icon {
        font-size: 1.8rem;
        line-height: 1;
      }
    }
  }
}

[data-lang="bg"] .cards .card.inactive h3:before {
  content: "Деактивиран";
}

.files {
  .card {
    .hospitalName {
      padding: 0;
      border: none;
      order: unset;
    }
  }
}

@media screen and (min-width: 1024px) {
  .files .cards.layout-list .card {
    .title {
      width: 100%;
    }

    div:not(.title) {
      white-space: nowrap;
    }
  }

  .cards.layout-list {
    display: table !important;
    padding: 0 24px !important;
    background: var(--white);
    border-radius: 24px;
    border: 1px solid var(--black-transparent-50);
    transition: none !important;
    width: 100%;
    overflow: hidden;
    box-shadow: rgb(165 149 149 / 10%) 0px 8px 24px;

    .card {
      display: table-row !important;
      transition: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      transform: none !important;
      background: transparent !important;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: calc(100% + 48px);
        height: 99%;
        left: -24px;
        z-index: -1;
        background: var(--black-transparent-35);
        opacity: 0;
        visibility: hidden;
        transition: 0.1s ease;
        pointer-events: none;
      }

      &:hover:after {
        opacity: 1;
        visibility: visible;
        z-index: 5;
      }

      &.inactive {
        box-shadow: none;

        h3:before {
          display: none;
        }
      }

      & > div,
      & > h3 {
        display: table-cell !important;
        position: static;
        width: auto;
        padding: 12px 8px;
        border: none;
        border-bottom: 1px solid var(--black-transparent-50);
        background: transparent;
        vertical-align: middle;
        transition: none !important;
        border-radius: 0 !important;
      }

      .email {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .roleName {
        text-align: center;
        font-weight: 700;
      }

      .fileSize:before {
        display: none;
      }

      .advice-summaries {
        width: 100% !important;
        padding-left: 90px !important;
        padding-right: 32px !important;

        .advice-summary {
          width: 50%;
          float: left;

          &:only-child {
            width: 100%;
          }
        }
      }

      .translations {
        width: 100%;
      }

      button,
      a {
        margin: 0;
        transition: none !important;
      }
    }
  }
}

.safari-browser {
  .cards.layout-list .card:after {
    display: none !important;
  }
}

.table {
  .table-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 48px;
  }
  .create {
    margin: 0 16px 0 0;

    .icon {
      font-size: 2.4rem;
      line-height: 0;
      position: relative;
      top: 2px;
      margin: 0 0 0 -2px;
    }
  }

  .search {
    display: inline-block;
    min-width: 240px;
    position: relative;
    margin: 0;
  }

  .search:after {
    content: "\f349";
    font-family: "icons";
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    font-weight: 600;
    pointer-events: none;
  }

  .rows-total {
    margin: 0 16px;
  }

  table {
    width: 100%;
    margin: 24px 0 0;
    border-collapse: separate;
    border-spacing: 0 16px;
  }

  td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }

  td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  thead {
    th {
      font-size: 1.8rem;
      padding: 0 5px;

      .icon {
        font-size: 2.4rem;
      }
    }

    .sortable {
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }

      .icon {
        margin: 0 0 0 12px;
        display: inline-block;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 21px;
        background: var(--primary-500);
        color: var(--white);
        width: 22px;
        height: 22px;
        border-radius: 50%;
        padding: 0 0 0 1px;
        position: relative;
        top: -2px;
        text-decoration: none;
      }
    }

    tr {
      background: transparent !important;
      padding: 16px 4px;
    }

    tr:first-child {
      display: none;
    }

    input {
      padding: 8px 12px;
      font-size: 1.2rem;
      margin: 8px 0 0;
      border-color: var(--primary-100);
      text-align: center;
      box-shadow: 0px 1px 1px 1px inset var(--black-transparent-100);
      border: none;
      max-width: 100%;
    }

    input:focus {
      box-shadow: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input + input {
      margin-left: 4px;
    }

    .flex {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  }

  tbody {
    tr {
      position: relative;

      &.inactive {
        opacity: 0.5;
      }

      &:hover {
        cursor: pointer;
      }
    }

    td {
      background: var(--white);
      padding: 16px;
      border: none;
      position: relative;
      text-align: center;
      border-bottom: 2px solid #eee;

      &:last-child:after {
        border: none;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 12px;
        height: calc(100% - 24px);
        border-right: 1px solid var(--primary-50);
      }
    }

    td.has-button {
      button {
        padding: 6px 6px;
        margin: 0 auto;
        font-size: 2rem;
        line-height: 1;
      }

      label {
        margin: 0 auto;
      }
    }
  }
}

.table-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 32px 0 0;

  .actions-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .action {
    margin: 0 8px 0 0;
    font-size: 2.4rem;
    line-height: 1;
  }

  input {
    margin: 0 8px 0 0;
    -webkit-appearance: none;
  }

  .page-skip {
    flex-shrink: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    margin: 0 16px 0 12px;

    input {
      margin: 0 0 0 16px;
      width: 80px;
    }
  }

  .rows-show-count {
    flex-grow: 1;
    -webkit-appearance: none;
  }

  .page-numbers {
    margin: 0 16px 0 12px;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1024) and (max-width: 1400px) {
  .table {
    .table-header,
    .table-actions,
    .cards.layout-list {
      max-width: 800px;
    }

    .search {
      min-width: 200px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .table {
    .search {
      min-width: 200px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .table {
    .table-header {
      flex-flow: row wrap;
      align-items: flex-start;
      margin: 0 0 24px;

      .create {
        margin: 0 16px 16px 0;
        order: 1;
        display: flex;
      }

      .search {
        order: 2;
        margin: 0;
        flex-grow: 1;
      }

      .rows-total {
        margin: 16px 0 0;
      }

      .layout-toggle {
        display: none;
      }
    }

    .cards {
      width: 100%;
      justify-content: space-between;
      margin: 0 !important;

      .card {
        width: calc(33% - 12px);
        margin: 0 8px 16px 0;
        padding: 0 16px 80px 16px;

        .update,
        .download {
          left: 16px;
        }

        div.delete {
          right: 16px;
        }

        .active {
          left: auto;
          right: 72px;
        }
      }
    }

    table {
      width: 100%;
      display: block;
      overflow-x: auto;

      td:first-child {
        position: sticky;
        left: 0;
        z-index: 50;
      }
    }

    .table-actions {
      flex-flow: column nowrap;

      .actions-group {
        justify-content: center;
        width: 100%;
        margin: 0 0 24px;

        button:last-child,
        select {
          margin: 0;
        }
      }

      .page-skip {
        margin: 0 16px 0 0;
      }

      .rows-show-count {
        flex-grow: 0;
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .table {
    .filters {
      margin: -8px 0 16px;

      .filter-group > div + div:not(.empty) {
        margin-left: 16px;
      }
    }

    .cards {
      .card {
        width: calc(50% - 12px);
        margin: 0 8px 16px 0;
      }

      &:nth-child(even) {
        margin: 0 0 16px 8px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .table {
    .filters {
      margin: 24px 0 0;
    }

    .table-header {
      .search {
        order: 2;
      }
    }

    .clear-filters {
      margin: -8px 0 28px;
    }

    .cards {
      .card {
        width: 100%;
        margin: 0 0 16px;
        padding-bottom: 84px;
        border: none;
      }
    }

    .table-actions {
      .page-skip input {
        width: 48px;
      }
    }
  }
}
