@charset "UTF-8";
@import "./styles/variables.scss";

* {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  font: 10px Raleway, Verdana, "Segoe UI", sans-serif;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  font-weight: 500;
  color: #333;
}

body {
  font-size: 1.6rem;
  color: var(--black-700);
  scroll-behavior: smooth;
  margin: 0;
}

html,
body,
#root,
.app,
.login-wrapper {
  height: 100%;
}

main,
details,
img {
  display: block;
}

img,
video,
audio {
  max-width: 100%;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  border-bottom: none;
  text-decoration: 1px solid underline;
  color: var(--blue-400);
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

b,
strong {
  font-weight: 600;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

summary {
  display: list-item;
}

[hidden],
template {
  display: none;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.text-wrap-normal {
  white-space: normal !important;
  text-overflow: unset !important;
}

.nowrap {
  white-space: nowrap !important;
}

.no-margins {
  margin: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 3.5rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.text-transform-0 {
  text-transform: none !important;
}

.relative {
  position: relative !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.text-center {
  text-align: center !important;
}

.d-none {
  display: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

/* End Common styles */

/* Headings */
h1,
h2,
h3,
h4 {
  font-weight: 700;
  font-family: "Raleway";
}

h1 {
  font-size: 3.2rem;
  margin: 24px 0 32px;

  span {
    opacity: 0.3;
    display: block;
    line-height: 1.1;
    padding: 8px 0 0;
  }
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.6rem;
}
/* End Headings */

/* Buttons, Forms and Inputs */
.input-wrapper {
  width: 100%;
  margin: 0 0 28px;

  &.inline {
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 0;
    width: auto;
  }

  &.required {
    > label:after {
      content: "*";
      font-size: 3.2rem;
      line-height: 0;
      opacity: 0.3;
      margin: 0 0 0 4px;
      position: relative;
      top: 9px;
    }
  }
}

button,
input,
optgroup,
select,
textarea {
  color: var(--white);
  background: var(--blue-400);
  font-family: inherit;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  text-transform: none;
  overflow: visible;
}

button,
.btn,
[type="button"],
[type="reset"],
[type="submit"] {
  font-weight: 500;
  display: block;
  padding: 10px 16px;
  font-size: 1.6rem;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 12px;
  background: var(--primary-500);
  color: var(--white);
  cursor: pointer;
  transition: 0.06s ease;
  white-space: nowrap;
}

button.secondary,
.btn.secondary {
  border: 1px solid var(--primary-100);
  background: var(--primary-50);
  color: var(--primary-400);
  font-size: 1.6rem;
}

button:disabled,
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: var(--white);
  pointer-events: none;
}

button.secondary:disabled,
.btn.secondary:disabled {
  opacity: 0.5;
  color: var(--blue-400);
  cursor: not-allowed;
  pointer-events: none;
}

button.loading,
.btn.loading {
  pointer-events: none;
  opacity: 0.5;

  &:before,
  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border: 2px solid var(--white);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    margin: 0 8px 0 0;
    animation: rotation 1s linear infinite;
  }

  &.secondary {
    &:before,
    &:before {
      border: 2px solid var(--primary-400);
      border-bottom-color: transparent;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.text {
  padding: 0;
  background: transparent;
  color: var(--primary-400);
  font-weight: 600;
  margin: 0 !important;
}

button.smaller {
  padding: 6px 10px !important;
  top: 1px;
  position: relative;
  font-size: 1.5rem;

  &.icon-only {
    padding: 5px 9px !important;
  }

  &.icon-left {
    .icon {
      margin-right: 4px;
    }
  }
}

input:disabled,
select:disabled,
textarea:disabled {
  background: var(--black-transparent-35);
  cursor: not-allowed;
}

input:read-only:not([type="file"]),
textarea:read-only:not([type="file"]) {
  border-color: var(--black-transparent-200);
  color: var(--black-transparent-500);
  cursor: not-allowed;
  background: var(--black-transparent-35);
}

input[type="file"] {
  border: none;
  padding: 12px 0 0;
  outline: none !important;
  border-radius: 0;
  background: transparent;
}

button:focus,
.btn:focus,
[type="button"]:focus,
[type="reset"]:focus,
[type="submit"]:focus {
  outline: 4px solid var(--primary-transparent-100);
}

button:active,
.btn:active,
[type="button"]:active,
[type="reset"]:active,
[type="submit"]:active {
  transform: translateY(2px);
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.caret-right:after {
  content: "\f142";
  font-family: icons;
  font-size: 2rem;
  line-height: 1;
  position: relative;
  top: 1px;
  margin: 0 0 0 2px;
}

form {
  max-width: 500px;
}

input,
select,
textarea {
  font-weight: 500;
  width: 100%;
  padding: 10px 16px;
  margin: 0;
  background: var(--white);
  color: var(--blue-900);
  border: 1px solid var(--black-transparent-300);
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  line-height: 1.5;
  transition: border-color 0.2s;
}

input:hover,
select:hover,
textarea:hover {
  border-color: var(--black-transparent-400);
}

input:focus,
select:focus,
textarea:focus,
.checkbox-label:focus {
  border-color: var(--black-transparent-300);
  outline: 4px solid var(--black-transparent-100);
}

input.small,
textarea.small {
  padding: 6px 12px;
  font-size: 1.5rem;
  border-color: var(--black-transparent-100);
}

input.line {
  padding: 6px 0;
  font-size: 1.5rem;
  border: none;
  border-bottom: 1px solid var(--black-transparent-100);
  border-radius: 0;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  display: block;
  margin: 0 0 8px;
  font-family: Raleway, Verdana, sans-serif;
  font-weight: 600;

  &.has-button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;

    &:after {
      order: 2;
      top: -9px !important;
    }

    button {
      padding: 4px !important;
      background: transparent;
      border: none;
      line-height: 1;
      color: var(--black-transparent-400);
      position: relative;
      top: -1px;
      margin: 0 0 0 auto !important;
      order: 3;

      & + button {
        margin: 0 !important;
      }
    }
  }
}

[type="radio"] {
  position: absolute;
  left: -9999px;
}

[type="radio"] + label {
  position: relative;
  padding: 4px 0 4px 28px;
  cursor: pointer;
  display: inline-block;
}

[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary-400);
  border-radius: 50%;
  background: var(--white);
}

[type="radio"] + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary-400);
  position: absolute;
  top: 9px;
  left: 5px;
  border-radius: 50%;
  transition: 0.1s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"] + label:hover:after {
  opacity: 1;
  background: var(--primary-300);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

[type="radio"]:checked + label:after {
  background: var(--primary-400);
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:disabled + label {
  pointer-events: none;
  opacity: 0.6;
}

[type="radio"]:disabled + label:after {
  background: var(--black-100);
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="submit"] {
  width: 100%;
  max-width: 500px;
  margin: 28px 0 0;
}

[type="submit"]:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

form button:not([type="submit"]) {
  margin: 24px 0;
}

form h3 {
  text-align: left;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 0.5px;
  margin: 0 0 24px;
  color: #444;
}

form h3:before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: var(--primary-500);
  margin: 0 10px 0 0;
  position: relative;
  border-radius: 2px;
}

.input-wrapper + h3 {
  margin-top: 48px;
}

.contact-person {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  width: 100%;
}
/* End Buttons, Forms and Inputs */

/* App */
.app {
  position: relative;
  z-index: 50;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  background: var(--primary-25);
}

main,
footer {
  position: relative;
}

/* End App */

/* Modals */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  background: var(--black-transparent-400);
}

.modal-background.toggled {
  opacity: 1;
  visibility: visible;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1.05);
  background: var(--white);
  color: var(--blue);
  padding: 24px 32px 32px;
  max-height: calc(100vh - 30px);
  width: calc(100% - 32px);
  max-width: 500px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease;
  z-index: 1100;
  overscroll-behavior: contain;
  overflow-y: auto;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.modal.toggled {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.modal:not(.toggled) * {
  pointer-events: none;
}

.modal-title {
  margin: 0 0 24px;
  font-size: 2.6rem;
}

.modal-title:before {
  display: block;
  color: #aaa;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
  line-height: 1.1;
}

.modal.creating .modal-title:before {
  content: "Creating:";
}

[data-lang="bg"] .modal.creating .modal-title:before {
  content: "Създаване:";
}

.modal.editing .modal-title:before {
  content: "Editing:";
}

[data-lang="bg"] .modal.editing .modal-title:before {
  content: "Редактиране:";
}

.modal.uploading .modal-title:before {
  content: "Uploading:";
}

[data-lang="bg"] .modal.uploading .modal-title:before {
  content: "Качване:";
}

.modal.testing .modal-title:before {
  content: "Testing:";
}

[data-lang="bg"] .modal.testing .modal-title:before {
  content: "Тестване:";
}

.wards .modal-title:before {
  display: none;
}

.modal-close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 2.8rem;
  line-height: 1;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background: var(--primary-100);
  }
}

@media screen and (min-width: 1024px) {
  .modal.large {
    max-width: 800px;
  }

  .modal.extra-large {
    max-width: 1200px;
    min-height: 83vh;
  }
}

@media screen and (min-width: 768px) {
  form.large {
    max-width: 734px;
  }

  .modal.large form,
  .modal.large input,
  .modal.large [type="submit"],
  form.large [type="submit"] {
    max-width: 100%;
    width: 100%;
  }

  .modal.large form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .flex-input-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .input-wrapper {
      flex-grow: 1;
      flex-basis: 1;
    }

    .input-wrapper + .input-wrapper,
    .input-wrapper + button {
      margin-left: 24px;
    }

    [type="submit"] {
      margin: 0;
    }

    button + button {
      margin: 0 0 0 16px;
    }

    button.reset-password {
      width: 230px;
      margin: 0 0 0 24px !important;
      flex-shrink: 0;
    }
  }
}
/* End Modals */

/* Form result message */
.form-message {
  margin: 16px 0;
  font-weight: 500;
  background: #eee;
  padding: 8px 16px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #ccc;
  }

  &.warning {
    background: var(--primary-100);
  }

  &.warning:after {
    background: var(--primary-400);
  }

  &.success {
    background: rgb(227, 255, 227);
  }

  &.success:after {
    background: rgb(105, 189, 105);
  }
}

/* End Inline message */

/* Popup message */
.message-popup {
  position: fixed;
  bottom: -16px;
  left: 50%;
  max-width: 400px;
  width: calc(100% - 32px);
  z-index: 5000;
  padding: 16px 20px;
  background: var(--white);
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease;
  transform: translateX(-50%);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  &.error {
    border-top: 4px solid var(--primary-400);
  }

  &.success {
    border-top: 4px solid var(--primary-400);
  }

  &.toggled {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-32px);
  }

  .message {
    padding: 0 32px 0 0;
  }
}
/* End Popup message */

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
    margin-top: 32px;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  form,
  button,
  input,
  select,
  textarea,
  [type="submit"] {
    max-width: 100%;
  }

  input,
  select,
  textarea {
    padding: 8px 12px;
  }

  button,
  .btn,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    padding: 8px 12px;
  }

  .container {
    padding: 0;
  }

  .app {
    flex-flow: column nowrap;
    padding: 16px;
  }

  .app.menu-opened {
    overflow-y: hidden;
  }

  .modal {
    top: auto;
    left: 0;
    bottom: -36px;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 70px);
    padding: 20px;
    transform: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .modal.toggled {
    transform: translateY(-36px);
  }

  .modal-title {
    font-size: 2.4rem;
  }

  .contact-person {
    flex-flow: column nowrap;
    align-items: stretch;
  }

  form button {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 2.8rem;
    line-height: 3.6rem;
    margin: 32px 0 24px;
  }
}
