.flex-input-wrapper.answer {
  & > .input-wrapper {
    margin: 0;
  }
}

.input-wrapper-answer {
  width: 8rem !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.input-wrapper.leads-to-target {
  width: 14rem !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.arrow-target {
  margin: 0 8px;
  font-size: 2rem;
  line-height: 1;
  opacity: 0.3;
}

.modal-advice {
  z-index: 2000 !important;

  &.modal-advice-correct {
    .modal-contents {
      & > .input-wrapper {
        margin-left: 0 !important;
      }
    }
  }
}
