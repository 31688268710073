@charset "UTF-8";

.notification-edit {
  .notification-select {
    margin: 0 0 32px;
    position: relative;
    z-index: 10;
  }

  .placeholder {
    display: inline-block;
    padding: 4px 12px;
    background: #eee;
    margin: 0 6px 6px 0;
    border-radius: 16px;
    font-size: 1.4rem;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
    transition: 0.2s ease;

    &.copied {
      background: var(--white);
      border-color: var(--black-transparent-200);
    }

    &:after {
      content: "Copied!";
      position: absolute;
      top: -70%;
      left: 50%;
      transform: translateX(-50%);
      background: var(--primary-100);
      padding: 4px 12px;
      border-radius: 32px;
      transition: 0.2s ease;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0 2px 2px -2px rgb(34 47 62 / 15%),
        0 8px 8px -4px rgb(34 47 62 / 7%);
    }

    &.copied:after {
      transform: translateX(-50%) translateY(-10%);
      opacity: 1;
      visibility: visible;
    }
  }

  .explanation {
    margin: 12px 0 0;
    font-size: 1.4rem;
    opacity: 0.7;
    float: right;
    font-weight: 500;
  }

  .tox-tinymce--disabled .tox-edit-area__iframe {
    background: var(--black-transparent-50);
    cursor: not-allowed;
  }

  span.tox-statusbar__branding {
    display: none !important;
  }
}

[data-lang="bg"] .notification-edit .placeholder:after {
  content: "Копирано!";
}
