.login {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    background: url(../../../public/images/logo.svg);
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: 4000px;
    z-index: -1;
    background-position: center;
    opacity: 0.1;
    filter: blur(30px);
  }

  h1 {
    font-weight: 100;
    border-top: 1px solid var(--black-transparent-100);
    width: 180px;
    text-align: center;
    margin: 16px 0 36px;
    padding: 8px 0 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1.6rem;
  }

  form {
    width: 100%;
    max-width: 360px;
    padding: 0 32px;
    flex-shrink: 0;

    button {
      width: 100%;
    }
  }

  .forgotten-password {
    width: 100%;
    max-width: 360px;
    padding: 0 32px;
    flex-shrink: 0;
    margin: 24px 0 0;

    button {
      color: var(--black-transparent-400);
      background: transparent;
      padding: 0;
      outline: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .login {
    &:before {
      background-size: 2000px;
    }
  }
}
