@charset "UTF-8";

.advice-for {
  .patient-info {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 0.9;
  }
}

.ask-for-advice h1 {
  max-width: 450px;
}

.advice-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.advice-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background: var(--black-transparent-300);
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;

  &.toggled {
    opacity: 1;
    visibility: visible;
  }
}

.consultation {
  margin: -4px 0 28px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  .input-wrapper {
    flex-grow: 0;
    margin: 0 !important;
    width: auto;

    &.checkbox {
      label {
        margin: 0 4px 0 0;
      }
    }

    &.ward {
      margin-top: -5px !important;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      top: 7px;
      position: relative;
      flex-grow: 1;

      & > div {
        flex-grow: 1;
      }
    }

    label {
      margin: 0 16px 0 0;
      font-weight: 600 !important;
    }
  }
}

.questions {
  width: 40%;
  max-width: 550px;

  .questions-list-wrapper {
    margin: 0;
    padding: 32px 0 0;

    b {
      font-weight: 600;
      margin: 0 0 10px;
      display: block;
    }
  }

  .questions-list {
    position: relative;
    margin: 0;
    padding: 0;
    counter-reset: question;
  }

  .question {
    background: var(--white);
    padding: 18px 24px 24px;
    margin: 24px 0;
    border-radius: 20px;
    position: relative;
    z-index: 5;
    list-style: none;
    counter-increment: question;
    border: 1px solid var(--black-transparent-50);
    box-shadow: rgba(165, 149, 149, 0.12) 0px 4px 8px;

    h3 {
      font-size: 2.6rem;
      margin: 0 0 24px;
      line-height: 3.4rem;

      &::before {
        margin: 0 8px 0 0;
      }
    }

    .answers {
      margin: 20px 0 0;

      label {
        font-size: 2rem;
        line-height: 1;
        padding-left: 32px;
      }

      .input-wrapper.number {
        max-width: 60%;
      }
    }
  }

  form button.next-question {
    margin: 16px 0 0;

    &::after {
      content: "\f054";
      font-family: "icons";
      font-size: 2.4rem;
      line-height: 0;
      position: relative;
      top: 2px;
      margin: 0 0 0 6px;
      display: inline-block;
    }
  }
}

.advice-finalized {
  .questions-list-wrapper {
    margin-top: -28px;
  }
}

.question.answered {
  background: var(--primary-25);
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    h3 {
      color: #333;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 1px;
    background-image: linear-gradient(
      90deg,
      var(--primary-200),
      var(--primary-200) 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 12px 1px;
  }

  h3 {
    display: flex;
    font-size: 1.7rem;
    line-height: 2.46rem;
    margin: 0 16px 10px 0;
    padding: 0 16px 0 2px;
    position: relative;
    z-index: 10;
    background: var(--primary-25);
    color: var(--black-300);
    max-width: 83%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;

    &::before {
      content: counter(question) ".";
      margin: 0 8px 0 0;
    }
  }

  .answer {
    margin: 0 0 0 auto;
    background: var(--primary-25);
    padding: 0 0 0 16px;
    color: #838383;
  }
}

.advice {
  width: 60%;
  margin: 0 0 0 40px;
  padding: 24px 32px 32px;
  background: var(--white);
  border-radius: 20px;
  position: relative;
  z-index: 1200;
  border: 1px solid var(--black-transparent-50);
  box-shadow: rgba(165, 149, 149, 0.1) 0px 8px 24px;

  &.completed:not(.accepted):not(.rejected) {
    box-shadow: rgba(165, 149, 149, 0.3) 0px 8px 24px;
  }

  &.rejected > div:not(.advice-header) {
    color: #aaa;
  }

  &.accepted {
    .medicament {
      background: rgba(64, 255, 64, 0.2509803922);
    }
  }

  &.placeholder,
  &.draft {
    opacity: 0.5;
    z-index: 0;
  }

  &.placeholder {
    .advice-header {
      border: none;
    }

    h2 {
      margin: 0 0 12px;
      font-size: 2rem;
    }

    .treatment-wrapper {
      margin: 0;
      border: none;
    }
  }

  .advice-header,
  .advice-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 3.2rem;
      margin: 0;
    }

    .advice-actions {
      button {
        margin: 0 0 0 16px;
      }
    }
  }
  .commas:not(:last-child) {
    &:after {
      content: ", ";
    }
  }
}

@media screen and (max-width: 1350px) {
  .advice-wrapper {
    flex-flow: column nowrap;
  }

  .questions {
    width: 100%;
    max-width: 100%;
    margin: 0 0 48px;
  }

  .advice {
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .advice-for {
    .patient-info {
      font-size: 2.2rem;
      line-height: 0.9;
    }
  }

  .questions {
    margin: 0 0 24px;

    .question:not(.answered) {
      h3 {
        font-size: 2.2rem;
        line-height: 2.8rem;
        margin: 0 0 16px;
      }

      .answers {
        margin: 12px 0 0;

        label {
          font-size: 1.6rem;
          line-height: 1.5;
          margin: 0;
        }
      }
    }
  }

  .advice-background {
    z-index: 800;
  }

  .advice {
    padding: 24px;
    z-index: 900;

    &.should-accept-or-reject {
      .advice-header {
        flex-flow: column nowrap;
        justify-content: flex-start;
      }

      .advice-actions {
        margin: 16px 0 0;
      }
    }

    .advice-header h2,
    .advice-actions h2 {
      font-size: 2.4rem;
      margin: 0;
    }

    h2:not(.advice-header-title) {
      font-size: 2rem;
      line-height: 2.8rem;
    }

    .treatment-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    .treatment-info {
      max-width: 100%;
    }

    .medicament {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }

    &.placeholder {
      padding: 24px 24px 16px;

      h2:not(.advice-header-title) {
        font-size: 1.8rem;
      }
    }
  }
}

.modal.testing .advice-background {
  background: linear-gradient(
    to bottom,
    var(--black-transparent-300),
    transparent
  ) !important;
}

// @media screen and (max-width: 768px) {
//   .advice {
//     padding: 24px;

//     .advice-header h2,
//     .advice-actions h2 {
//       font-size: 2.4rem;
//     }

//     h2:not(.advice-header-title) {
//       font-size: 1.4rem;
//     }

//     h2:not(.advice-header-title):before {
//       width: 12px;
//       height: 12px;
//     }

//     &.placeholder h2 {
//       margin: 0 0 12px;
//     }

//     .advice-header {
//       flex-flow: column nowrap;
//       justify-content: flex-start;
//       align-items: flex-start;
//     }

//     .advice-actions {
//       margin: 12px 0 12px;

//       button {
//         margin: 0 16px 0 0 !important;
//       }
//     }

//     .medicament {
//       font-size: 2rem;
//       line-height: 2.8rem;
//     }

//     .medicament-wrapper + .medicament-wrapper {
//       padding-left: 24px;
//     }
//   }

//   .consultation {
//     flex-flow: column nowrap;
//     justify-content: flex-start;
//     align-items: flex-start;
//   }

//   .questions .question.active h3 {
//     font-size: 2rem;
//     line-height: 2.8rem;
//   }
// }

// @media screen and (max-width: 600px) {
//   .consultation {
//     flex-flow: column nowrap;
//     justify-content: flex-start;
//     align-items: flex-start;
//     display: block;
//   }

//   .questions .advice-for {
//     flex-flow: column nowrap;
//     justify-content: flex-start;
//     align-items: flex-start;

//     .input-wrapper + .input-wrapper {
//       margin-top: 24px;
//     }
//   }
// }
