.hospital-advice-builder {
  .table-results {
    & > .filters {
      display: none;
    }
  }

  &.card-big {
    .card {
      width: 700px;
    }

    .search,
    .filters,
    .table-actions {
      display: none;
    }

    .layout-toggle {
      margin: 0;
    }
  }

  .card {
    width: 320px;
    padding: 0 24px 20px;

    .wi-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-right: 16px;
    }

    h4 {
      display: inline-flex;
      font-weight: 600;
      background: var(--black-transparent-50);
      width: auto !important;
      padding: 2px 10px;
      border-radius: 8px;
      margin: 20px 0 4px;
      font-size: 1.3rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }

    .tags {
      margin: 0;

      .tag {
        font-weight: 700;
        font-variant: lining-nums;
        background: transparent;
        text-transform: lowercase;
        padding: 0;
        margin: 0;
        color: #aaa;

        &.emphasized {
          color: #f9a100;
        }
      }
    }

    .btn-push-all {
      margin: 0 !important;
    }

    .wi-row.push-all {
      margin-top: 6px;
    }
  }
}

.workflowInstance,
.missingWorkflow,
.updatedWorkflow {
  vertical-align: baseline !important;

  .wi-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 4px 0;

    div:first-of-type {
      flex-basis: auto;
    }

    div {
      flex-basis: content;
    }

    .wi-inner-row {
      width: auto;
      display: flex;

      button {
        margin: 0 0 0 8px;
      }
    }
  }
}

.workflowInstance:not(:empty) + .missingWorkflow {
  margin-top: 20px;
}
