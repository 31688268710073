.nomenclatures {
  .select-nomenclature {
    max-width: 550px;
  }

  .nomenclature-form {
    margin-top: -8px;

    .form-message {
      width: 100%;
      margin: 0 0 16px;
    }

    .translation-languages {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;

      .translation-language {
        width: 100%;

        & + .translation-language {
          margin-left: 32px;
        }
      }

      .language-name {
        font-weight: 600;
        margin: 0 0 16px;
        text-align: center;
        text-transform: uppercase;
        background: var(--black-transparent-50);
        padding: 6px 0;
        font-size: 1.4rem;
        letter-spacing: 0.3px;
        border-radius: 10px;

        &.complete:before,
        &.partial:before {
          content: "\f12c";
          font-family: "icons";
          margin: 0 4px 0 -8px;
          line-height: 1;
        }

        &.partial {
          background: var(--primary-100);
        }

        &.partial:before {
          content: "\f028";
          font-size: 1.7rem;
        }

        &.complete {
          background: #b6f5b6;
        }

        &.complete:before {
          content: "\f12c";
          font-size: 1.8rem;
        }
      }
    }
  }

  .table-results {
    & > .filters {
      display: none;
    }
  }

  .nomenclature {
    padding-top: 20px !important;

    h3 {
      margin: 0;
      font-size: 1.7rem;
    }

    .translation {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      &.none {
        opacity: 0.5;
      }
    }

    .translation-language {
      text-transform: uppercase;
      font-size: 1.4rem;
      flex-grow: 0;
      width: auto !important;
      margin: 0 32px 0 0;
    }

    .translation-value {
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .nomenclatures {
    .nomenclature-form {
      .translation-languages {
        flex-flow: column nowrap;

        .translation-language {
          margin: 0 !important;
        }
      }
    }

    .nomenclature .translation {
      white-space: normal;
    }
  }
}
