.question .accordion-header {
  position: relative;
  background: var(--primary-25);
  padding: 16px;
  border-radius: 10px;
  margin: 0 0 16px;
  border: 1px solid var(--black-transparent-50);
  display: flex;
  justify-content: space-between;

  & > .input-wrapper.toggled {
    label {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  &:hover {
    cursor: pointer;
    background: var(--primary-50);
    border-color: var(--black-transparent-100);
  }

  & > .input-wrapper {
    & > label {
      cursor: pointer;
    }
  }

  .delete-treatment {
    border: none;
    background: none;
    font-size: 2rem;
    color: var(--black-700);
    padding: 0;
  }
}

.accordeon-body {
  position: relative;
  padding: 32px 16px 32px;
  margin: -28px 0 32px;
  background: var(--primary-25);
  border: 1px solid var(--black-transparent-50);
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .medicament-form {
    h3 {
      margin: 20px 0 !important;
      font-size: 1.6rem !important;
      line-height: unset !important;
    }
  }
}
