.archive-advice-form {
  .archive-advice-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  .archived-advice-summary {
    width: 33%;
    flex-shrink: 0;
    border-right: 1px solid var(--black-transparent-50);
    margin: 0 32px 0 0;
    background: var(--primary-50);
    padding: 0 16px;
    border: 1px solid var(--primary-100);
    border-radius: 16px;

    .advice-summary {
      &:before {
        display: none;
      }

      .disease {
        font-weight: 700;
      }
    }
  }

  .archived-advice-questions {
    counter-reset: archive-advice-headers;

    h2 {
      counter-increment: archive-advice-headers;
    }

    h2:before {
      content: counter(archive-advice-headers) ".";
      margin: 0 8px 0 0;
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .input-wrapper {
    margin: 0;
  }

  .drug-reactions {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    background: var(--primary-25);
    border: 1px solid var(--primary-50);
    padding: 8px 16px 12px;
    border-radius: 10px;

    .input-checkbox {
      width: 50%;

      label {
        padding: 0 16px 4px 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:after {
          top: 5px;
          left: 7px;
        }
      }

      [type="checkbox"] + label:before {
        background-color: var(--white);
      }
    }
  }

  .iw-add {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 16px 0 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      border-top: 1px dashed var(--black-transparent-100);
      z-index: -1;
    }

    button {
      margin: 0;
    }
  }

  button[type="submit"] {
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .archive-advice-form {
    .archive-advice-wrapper {
      flex-flow: column nowrap;

      .archived-advice-summary {
        width: 100%;
        margin: 0 0 16px;
        padding: 0 16px 16px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .archive-advice-form {
    .drug-reactions {
      .input-checkbox {
        width: 100%;
      }
    }
  }
}
