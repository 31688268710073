.advice-builder-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;

  .advice-form {
    width: 50%;
    max-width: 700px;
    flex-shrink: 0;
    padding-right: 24px;

    .action-button {
      display: flex;
    }

    & > div:last-child {
      margin: 0;
    }

    .questions {
      width: 100% !important;
      max-width: 100% !important;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    .workflow-question {
      padding-top: 4px;
      padding-bottom: 16px;
      position: relative;

      &[data-index="0"] {
        padding-top: 0;
      }

      .answer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        transition-delay: 0.4s;

        &.highlighted {
          font-weight: 700;
        }

        button.correct-advice {
          margin-left: 12px;
        }
      }
    }

    .advice-builder-design {
      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  .advice-chart {
    width: 50%;
    height: calc(100vh - 40px);
    flex-shrink: 0;
    flex-grow: 1;
    position: sticky;
    top: 0;
    z-index: 5;
  }
}

.workflow-actions {
  margin: 16px 0 54px;

  button {
    margin: 0 8px 0 0;
  }
}

.workflow-comparison {
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: stretch;

  .advice-chart {
    width: 50%;
    height: 100%;

    h2 {
      text-align: center;
    }

    &:first-child {
      border-right: 1px solid var(--black-transparent-50);
    }
  }
}
