.nav-sidebar {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  padding: 0 12px;
  max-height: calc(100% - 200px);
  overflow-y: auto;
  scrollbar-width: 10px;
  scrollbar-color: var(--black-transparent-100) transparent;

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 4px solid var(--white);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--black-transparent-100);
  }
}

.nav-link {
  display: block;
  padding: 12px 16px;
  flex-shrink: 0;
  color: var(--black-700);
  text-decoration: none;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: transform 0.2s ease;

  &.current-page {
    color: var(--white);
    border-color: var(--white-transparent-100);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    background: var(--primary-500);
    left: 3px;
    transform: translateX(-28px);
    padding-left: 44px;
    pointer-events: none;

    &:hover,
    &:active {
      background: var(--primary-500);
      color: var(--white);
    }
  }

  &:hover {
    background: var(--black-transparent-50);
    border-color: var(--black-transparent-50);
  }

  .icon {
    margin: 0 12px 0 0;
    font-size: 2.4rem;
    line-height: 1;
    position: relative;
    top: 1px;

    &:before {
      text-shadow: 0 0 var(--black-900);
    }
  }
}

.sidebar-collapsed {
  .nav-sidebar {
    overflow: hidden;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .nav-sidebar a {
    width: 100%;
    text-align: center;

    &.current-page {
      transform: translateX(-14px);
    }

    .icon {
      margin: 0;
    }

    .text {
      display: none;
    }
  }
}
