aside {
  position: sticky;
  z-index: 1000;
  top: 0;
  height: 100%;
  min-width: 300px;
  flex-shrink: 0;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0 16px;
  background: var(--white);
  box-shadow: rgb(165 149 149 / 10%) 0px 8px 24px;
  border-right: 1px solid var(--black-transparent-50);

  .logo {
    margin: 12px 0 0;
    padding: 24px 24px 32px 27px;
  }

  .nav-toggler {
    display: none;
  }
}

aside .btn-collapse-sidebar {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 34px;
  left: calc(100% - 44px);
  z-index: 10;
  padding: 1px;
  background: var(--black-transparent-50);
  line-height: 1;
  color: var(--black-100);
  font-size: 2.4rem;
  white-space: nowrap;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: 0.2s ease;
  outline: none !important;

  &:hover {
    border-radius: 16px;
    background: var(--primary-500);
    color: var(--white);
    padding: 1px 12px 1px 1px;
  }

  .text {
    display: none;
    font-size: 1.4rem;
  }

  &:hover .text {
    display: block;
  }

  .icon {
    display: inline-block;
    transition: transform 0.3s ease;
    transition-delay: 0.3s;
  }
}

aside .profile {
  margin: auto 0 0;
  padding: 16px 12px 0;
  border-top: 1px solid var(--black-transparent-50);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .logout {
    .icon {
      margin: 0;
    }
  }
}

.sidebar-collapsed {
  aside {
    min-width: auto;

    .logo {
      padding-left: 30px;
    }
  }

  .btn-collapse-sidebar {
    color: var(--black-transparent-400);
    border: 3px solid var(--white);
    background: var(--primary-100);
    left: calc(100% - 14px);
    top: 33px;

    .icon {
      transform: rotateZ(180deg);
    }
  }

  .profile .text,
  .logout {
    display: none;
  }

  .profile .icon {
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .menu-opened aside {
    min-height: 100%;
  }

  aside {
    height: 56px;
    padding: 16px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    background: var(--white-transparent-900);
    backdrop-filter: blur(5px);
    transform: translate3d(0, 0, 0);

    .btn-collapse-sidebar {
      display: none;
    }
  }

  aside .nav-sidebar {
    padding: 0;
    margin: 24px 0 12px;
    overflow: visible;
    flex-grow: 0;
    transition: 0.3s ease;
    opacity: 0;
    visibility: hidden;
    max-height: unset;
    height: auto;

    .nav-link {
      padding: 10px 0;
    }

    .nav-link.current-page {
      padding: 10px 14px 11px;
      margin: 4px -12px 8px;
      transform: none;
    }
  }

  aside .profile {
    padding: 16px 0 0;
    margin: 0;
    transition: 0.3s ease;
    opacity: 0;
    visibility: hidden;

    .nav-link {
      padding: 0;
    }
  }

  button.nav-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 8px;
    box-shadow: none;
    background: transparent !important;
    position: absolute;
    right: 6px;
    top: 16px;
    z-index: 10;

    &:active {
      transform: none;
    }

    &:focus {
      outline: none;
    }

    .nav-toggler-inner {
      width: 28px;
      height: 7px;
      position: relative;
    }

    .line {
      display: inline-block;
      position: absolute;
      width: 28px;
      height: 2px;
      background: var(--black-700);
    }

    .line:first-child {
      top: 0;
      left: 0;
      transition: 0.2s;
    }

    .line:nth-child(2) {
      top: 7px;
      left: 0;
      transition: 0.2s;
    }
  }

  .menu-opened {
    .nav-sidebar {
      opacity: 1;
      visibility: visible;
    }

    .profile {
      position: static;
      opacity: 1;
      visibility: visible;
    }

    .nav-toggler {
      span:first-child {
        transform: rotate(45deg) translateX(2px) translateY(3px);
      }

      span:nth-child(2) {
        transform: rotate(-45deg) translateX(2px) translateY(-3px);
      }
    }
  }
}
