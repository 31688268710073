.input-switch {
  [type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  [type="checkbox"] + label {
    cursor: pointer;
    text-indent: -9999px;
    width: 48px;
    height: 20px;
    background: #ccc;
    display: block;
    border-radius: 100px;
    position: relative;
    color: transparent;
    margin: 0;
    box-shadow: 0 3px 3px var(--black-transparent-100) inset;
  }

  [type="checkbox"] + label:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -1px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 90px;
    transition: 0.2s;
    border: 1px solid #ddd;
  }

  [type="checkbox"]:checked + label {
    background: #2bd951;
  }

  [type="checkbox"]:checked + label:after {
    left: calc(100% + 1px);
    transform: translateX(-100%);
  }
}
