.modal.modal-hospital {
  max-width: 1200px;

  .modal-contents {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .hospital {
      width: 50%;
      flex-shrink: 0;
      margin: 0 32px 0 0;
      padding: 0 32px 0 0;
      border-right: 1px solid var(--black-transparent-100);

      [type="submit"] {
        margin: 8px 0 0;
      }
    }

    .wards {
      width: calc(50% - 18px);
      flex-shrink: 0;
      counter-reset: wards;

      .wards-list {
        margin: 0 0 32px;
      }
    }
  }

  .add-new {
    margin: 0 0 24px;

    .icon {
      display: inline-block;
      font-size: 2.4rem;
      line-height: 0;
      position: relative;
      top: 2px;
      transition: 0.1s ease;
    }

    &.creating {
      .icon {
        transform: rotateZ(45deg);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal.modal-hospital {
    .modal-contents {
      flex-flow: column nowrap;
      position: relative;

      .hospital {
        width: 100%;
        border: none;
        padding: 0;
      }

      .wards {
        width: 100%;
        border: none;
        padding: 0;
        margin: 40px 0 0;
      }
    }
  }
}
