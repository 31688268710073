@import "../../styles/variables.scss";

.logo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  color: var(--blue-900);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;

  .image {
    width: 24px;
    margin: 0 10px 0 0;
    flex-shrink: 0;
    position: relative;
    top: -1px;
    transition: 0.1s ease-out;
  }

  .text {
    margin: 0;
    flex-shrink: 0;
    font-size: 3rem;
  }

  .text-red {
    color: var(--primary-500);
  }

  &.light {
    .text-red {
      color: var(--primary-300);
    }
  }

  &.light:hover {
    .image {
      opacity: 1;
    }
  }
}

.sidebar-collapsed {
  .logo {
    .text {
      display: none;
    }

    .image {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  aside .logo {
    padding: 0;
    margin: 0;

    .image {
      width: 23px;
      top: -1px;
      margin: 0 8px 0 0;
    }

    .text {
      font-size: 2.4rem;
    }
  }
}
