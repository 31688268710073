.flowchart {
  flex-grow: 1;
  height: 100%;
  min-width: 300px;
  min-height: 300px;

  button.react-flow__controls-button {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
  }
}

.react-flow__node-advice {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.flowchart-no-data {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    max-width: 170px;
    text-align: center;
    opacity: 0.6;

    .icon {
      display: block;
      margin: 0 0 4px;
      font-size: 2.8rem;
      line-height: 1;
      opacity: 0.8;
    }
  }
}
