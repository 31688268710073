.page-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 0 32px;

  h1 {
    margin: 0;
  }

  .btn-back {
    background: transparent;
    color: var(--black-700);
    font-size: 3rem;
    line-height: 1;
    padding: 0;
    margin: 0 16px 0 -4px;
  }
}

@media screen and (max-width: 1024px) {
  .page-title {
    margin: 24px 0;

    .btn-back {
      margin: 0 6px 0 -4px;
    }
  }
}
