.react-flow__node {
  max-width: 150px;

  &:not(.react-flow__node-advice) {
    &:hover {
      cursor: pointer !important;
      background: var(--primary-50) !important;
    }
  }
}

.react-flow__node-question {
  text-align: center !important;
  padding: 8px 12px !important;
  width: 150px !important;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .content {
    width: 100%;
  }

  button.copy-id {
    position: absolute;
    left: calc(100% + 8px);
    top: 50%;
    padding: 4px 10px !important;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    z-index: 1500;
  }

  &:hover {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      height: 100%;
      z-index: -1;
    }

    button.copy-id {
      opacity: 1;
      visibility: visible;
    }
  }
}

.react-flow__handle {
  width: 7px !important;
  height: 7px !important;
  border: 2px solid var(--white) !important;
  background: var(--black-500) !important;
}

.react-flow__node-advice {
  border-radius: 24px !important;
  background: rgb(227 255 227) !important;
  padding: 16px 12px !important;
  text-align: left !important;
  cursor: pointer !important;
  max-width: 180px !important;

  label {
    font-weight: 700 !important;
    margin: 0 0 6px !important;
    text-align: center;
    font-size: 1.5rem !important;
    line-height: 1.3 !important;
  }

  .input-wrapper {
    font-size: 1.2rem;

    & + .input-wrapper {
      margin-top: 16px !important;
    }
  }

  .medicament-summary {
    margin: 0 0 12px;

    .medicament-administration {
      display: block;
    }
  }

  h3 {
    margin: 12px 0 4px;
    font-size: 1.3rem;
  }

  .summary-treatment-info {
    /*Clip to 3 lines*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    color: var(--black-100);
    margin: 0;

    & + .summary-treatment-info {
      margin-top: 12px;
    }

    b {
      color: var(--black-700);
    }

    & + .summary-treatment-info {
      margin-top: 8px;
    }
  }

  .summary-bacteria {
    display: none;
    margin: 0;
    font-size: 1.2rem;

    &:before {
      font-size: 1.6rem !important;
    }
  }

  .node-opened {
    max-height: 999999px !important;

    .summary-treatment-info {
      -webkit-line-clamp: unset;
      overflow: visible;
    }

    .summary-bacteria {
      display: block;
    }
  }

  hr {
    display: block;
    margin: 16px 0;
    border: none;
    border-top: 2px solid var(--black-transparent-100);
    box-shadow: none;
    outline: none;
  }

  b {
    margin: 0 5px 0 0;
  }
}
