.tabs {
  margin: 0 0 24px;

  .tabs-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    button {
      padding: 4px 12px;
      margin: 0 8px 0 0 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent !important;
      outline: none !important;
      transform: none;
      flex-grow: 0;
      font-size: 1.5rem;
      transition: none;

      &.active {
        padding: 12px 16px 6px;
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--black-700);
        margin-bottom: -1px !important;
      }

      &:not(.active) {
        background: #fff;
        color: #aaa;
        border-color: var(--primary-100);
      }
    }
  }

  .tabs-content {
    background: var(--primary-50);
    padding: 20px 24px 2px;
    border-radius: 12px;
    border-top-left-radius: 0;
    border: 1px solid var(--primary-100);
  }
}
