.workflow-question {
  transition: 0.3s ease;

  &.highlighted {
    background: var(--primary-100) !important;
  }
}

.labels {
  .input-wrapper {
    margin: 0 0 4px;

    &.target {
      margin-left: 36px !important;
    }
  }
}

.parent-question {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  margin: 12px 0 -20px;
  font-weight: 700;
  opacity: 0.4;
  font-size: 1.3rem;
  letter-spacing: 0.1px;
  padding-right: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    display: block;
  }

  .question-text {
    max-width: 83%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    font-size: 2rem;
    line-height: 1;
    margin: 0 4px;
    display: inline-block;
    position: relative;
    top: -1px;
  }
}
