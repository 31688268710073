@font-face {
  font-family: "icons";
  src: url("../../../public/icons/icons.eot?4312907");
  src: url("../../../public/icons/icons.eot?4312907#iefix")
      format("embedded-opentype"),
    url("../../../public/icons/icons.woff2?4312907") format("woff2"),
    url("../../../public/icons/icons.woff?4312907") format("woff"),
    url("../../../public/icons/icons.ttf?4312907") format("truetype"),
    url("../../../public/icons/icons.svg?4312907#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.has-caret-down:after {
  content: "\f140";
  font-family: "icons";
  font-size: 24px;
  line-height: 1;
  position: relative;
  top: 4px;
  margin: 0 0 0 4px;
}

.icon-leading-text {
  font-size: 2rem;
  line-height: 1;
  margin: 0 4px 0 0;
}

.icon-arrow-down-circle:before {
  content: "\e800";
}
.icon-arrow-left-circle:before {
  content: "\e801";
}
.icon-arrow-right-circle:before {
  content: "\e802";
}
.icon-arrow-up-circle:before {
  content: "\e803";
}
.icon-drop:before {
  content: "\e804";
}
.icon-question:before {
  content: "\e805";
}
.icon-pencil-1:before {
  content: "\e806";
}
.icon-cross:before {
  content: "\e9c4";
}
.icon-account:before {
  content: "\f004";
}
.icon-alarm:before {
  content: "\f020";
}
.icon-alarm-plus:before {
  content: "\f024";
}
.icon-alert:before {
  content: "\f026";
}
.icon-alert-circle:before {
  content: "\f028";
}
.icon-alert-octagon:before {
  content: "\f029";
}
.icon-arrange-bring-forward:before {
  content: "\f03d";
}
.icon-arrange-bring-to-front:before {
  content: "\f03e";
}
.icon-arrange-send-backward:before {
  content: "\f03f";
}
.icon-arrange-send-to-back:before {
  content: "\f040";
}
.icon-arrow-down:before {
  content: "\f045";
}
.icon-arrow-left:before {
  content: "\f04d";
}
.icon-arrow-right:before {
  content: "\f054";
}
.icon-arrow-up:before {
  content: "\f05d";
}
.icon-bank:before {
  content: "\f070";
}
.icon-flask:before {
  content: "\f093";
}
.icon-flask-empty:before {
  content: "\f094";
}
.icon-bell:before {
  content: "\f09a";
}
.icon-bell-off:before {
  content: "\f09b";
}
.icon-bluetooth:before {
  content: "\f0af";
}
.icon-book:before {
  content: "\f0ba";
}
.icon-book-multiple:before {
  content: "\f0bb";
}
.icon-bookmark:before {
  content: "\f0c0";
}
.icon-border-all:before {
  content: "\f0c7";
}
.icon-border-bottom:before {
  content: "\f0c8";
}
.icon-border-horizontal:before {
  content: "\f0ca";
}
.icon-border-inside:before {
  content: "\f0cb";
}
.icon-border-left:before {
  content: "\f0cc";
}
.icon-border-none:before {
  content: "\f0cd";
}
.icon-border-outside:before {
  content: "\f0ce";
}
.icon-border-right:before {
  content: "\f0cf";
}
.icon-border-top:before {
  content: "\f0d1";
}
.icon-border-vertical:before {
  content: "\f0d2";
}
.icon-briefcase:before {
  content: "\f0d6";
}
.icon-calendar:before {
  content: "\f0ed";
}
.icon-camcorder:before {
  content: "\f0fc";
}
.icon-camera:before {
  content: "\f100";
}
.icon-cart:before {
  content: "\f110";
}
.icon-chart-areaspline:before {
  content: "\f127";
}
.icon-chart-bar:before {
  content: "\f128";
}
.icon-chart-histogram:before {
  content: "\f129";
}
.icon-chart-line:before {
  content: "\f12a";
}
.icon-chart-pie:before {
  content: "\f12b";
}
.icon-check:before {
  content: "\f12c";
}
.icon-chevron-double-down:before {
  content: "\f13c";
}
.icon-chevron-double-left:before {
  content: "\f13d";
}
.icon-chevron-double-right:before {
  content: "\f13e";
}
.icon-chevron-double-up:before {
  content: "\f13f";
}
.icon-chevron-down:before {
  content: "\f140";
}
.icon-chevron-left:before {
  content: "\f141";
}
.icon-chevron-right:before {
  content: "\f142";
}
.icon-chevron-up:before {
  content: "\f143";
}
.icon-clipboard:before {
  content: "\f147";
}
.icon-clipboard-check:before {
  content: "\f14c";
}
.icon-clipboard-text:before {
  content: "\f14e";
}
.icon-clock:before {
  content: "\f150";
}
.icon-closed-caption:before {
  content: "\f15e";
}
.icon-cloud:before {
  content: "\f15f";
}
.icon-cloud-download:before {
  content: "\f162";
}
.icon-cloud-upload:before {
  content: "\f167";
}
.icon-comment:before {
  content: "\f17a";
}
.icon-comment-alert:before {
  content: "\f17d";
}
.icon-comment-text:before {
  content: "\f188";
}
.icon-console:before {
  content: "\f18d";
}
.icon-content-cut:before {
  content: "\f190";
}
.icon-content-duplicate:before {
  content: "\f191";
}
.icon-content-paste:before {
  content: "\f192";
}
.icon-content-save:before {
  content: "\f193";
}
.icon-content-save-all:before {
  content: "\f194";
}
.icon-credit-card:before {
  content: "\f19b";
}
.icon-credit-card-scan:before {
  content: "\f19d";
}
.icon-crop:before {
  content: "\f19e";
}
.icon-crop-free:before {
  content: "\f19f";
}
.icon-currency-eur:before {
  content: "\f1ad";
}
.icon-currency-gbp:before {
  content: "\f1ae";
}
.icon-currency-rub:before {
  content: "\f1b1";
}
.icon-currency-usd:before {
  content: "\f1b3";
}
.icon-delete:before {
  content: "\f1c0";
}
.icon-diamond-stone:before {
  content: "\f1c8";
}
.icon-dots-horizontal:before {
  content: "\f1d8";
}
.icon-dots-vertical:before {
  content: "\f1d9";
}
.icon-download:before {
  content: "\f1da";
}
.icon-eject:before {
  content: "\f1ea";
}
.icon-email:before {
  content: "\f1ee";
}
.icon-email-open:before {
  content: "\f1ef";
}
.icon-eye:before {
  content: "\f208";
}
.icon-eye-off:before {
  content: "\f209";
}
.icon-factory:before {
  content: "\f20f";
}
.icon-fast-forward:before {
  content: "\f211";
}
.icon-file:before {
  content: "\f214";
}
.icon-file-multiple:before {
  content: "\f222";
}
.icon-flag:before {
  content: "\f23b";
}
.icon-flash:before {
  content: "\f241";
}
.icon-folder:before {
  content: "\f24b";
}
.icon-folder-multiple:before {
  content: "\f253";
}
.icon-folder-plus:before {
  content: "\f257";
}
.icon-format-align-center:before {
  content: "\f260";
}
.icon-format-align-justify:before {
  content: "\f261";
}
.icon-format-align-left:before {
  content: "\f262";
}
.icon-format-align-right:before {
  content: "\f263";
}
.icon-format-bold:before {
  content: "\f264";
}
.icon-format-clear:before {
  content: "\f265";
}
.icon-format-float-center:before {
  content: "\f267";
}
.icon-format-float-left:before {
  content: "\f268";
}
.icon-format-float-none:before {
  content: "\f269";
}
.icon-format-float-right:before {
  content: "\f26a";
}
.icon-format-indent-decrease:before {
  content: "\f275";
}
.icon-format-indent-increase:before {
  content: "\f276";
}
.icon-format-italic:before {
  content: "\f277";
}
.icon-format-line-spacing:before {
  content: "\f278";
}
.icon-format-list-bulleted:before {
  content: "\f279";
}
.icon-format-list-numbers:before {
  content: "\f27b";
}
.icon-format-quote-close:before {
  content: "\f27e";
}
.icon-format-underline:before {
  content: "\f287";
}
.icon-format-wrap-inline:before {
  content: "\f288";
}
.icon-format-wrap-square:before {
  content: "\f289";
}
.icon-format-wrap-tight:before {
  content: "\f28a";
}
.icon-format-wrap-top-bottom:before {
  content: "\f28b";
}
.icon-forum:before {
  content: "\f28c";
}
.icon-fullscreen:before {
  content: "\f293";
}
.icon-fullscreen-exit:before {
  content: "\f294";
}
.icon-gift:before {
  content: "\f2a1";
}
.icon-grid:before {
  content: "\f2c1";
}
.icon-grid-off:before {
  content: "\f2c2";
}
.icon-group:before {
  content: "\f2c3";
}
.icon-heart:before {
  content: "\f2d1";
}
.icon-help:before {
  content: "\f2d6";
}
.icon-help-circle:before {
  content: "\f2d7";
}
.icon-hexagon:before {
  content: "\f2d8";
}
.icon-home:before {
  content: "\f2dc";
}
.icon-image:before {
  content: "\f2e9";
}
.icon-information:before {
  content: "\f2fc";
}
.icon-label:before {
  content: "\f315";
}
.icon-layers:before {
  content: "\f328";
}
.icon-lightbulb:before {
  content: "\f335";
}
.icon-link:before {
  content: "\f337";
}
.icon-link-variant:before {
  content: "\f339";
}
.icon-lock:before {
  content: "\f33e";
}
.icon-lock-open:before {
  content: "\f33f";
}
.icon-login:before {
  content: "\f342";
}
.icon-logout:before {
  content: "\f343";
}
.icon-magnify:before {
  content: "\f349";
}
.icon-magnify-minus:before {
  content: "\f34a";
}
.icon-magnify-plus:before {
  content: "\f34b";
}
.icon-map-marker:before {
  content: "\f34e";
}
.icon-memory:before {
  content: "\f35b";
}
.icon-menu:before {
  content: "\f35c";
}
.icon-message:before {
  content: "\f361";
}
.icon-message-alert:before {
  content: "\f362";
}
.icon-message-image:before {
  content: "\f364";
}
.icon-message-processing:before {
  content: "\f366";
}
.icon-message-reply:before {
  content: "\f367";
}
.icon-message-text:before {
  content: "\f369";
}
.icon-message-video:before {
  content: "\f36b";
}
.icon-microphone:before {
  content: "\f36c";
}
.icon-microphone-off:before {
  content: "\f36d";
}
.icon-minus:before {
  content: "\f374";
}
.icon-minus-box:before {
  content: "\f375";
}
.icon-minus-circle:before {
  content: "\f376";
}
.icon-monitor:before {
  content: "\f379";
}
.icon-monitor-multiple:before {
  content: "\f37a";
}
.icon-nfc-variant:before {
  content: "\f398";
}
.icon-note:before {
  content: "\f39a";
}
.icon-note-plus:before {
  content: "\f39c";
}
.icon-note-text:before {
  content: "\f39e";
}
.icon-octagon:before {
  content: "\f3c3";
}
.icon-paperclip:before {
  content: "\f3e2";
}
.icon-pause:before {
  content: "\f3e4";
}
.icon-pencil:before {
  content: "\f3eb";
}
.icon-phone:before {
  content: "\f3f2";
}
.icon-pin:before {
  content: "\f403";
}
.icon-pin-off:before {
  content: "\f404";
}
.icon-play:before {
  content: "\f40a";
}
.icon-plus:before {
  content: "\f415";
}
.icon-plus-box:before {
  content: "\f416";
}
.icon-plus-circle:before {
  content: "\f417";
}
.icon-power:before {
  content: "\f425";
}
.icon-presentation:before {
  content: "\f428";
}
.icon-presentation-play:before {
  content: "\f429";
}
.icon-printer:before {
  content: "\f42a";
}
.icon-redo-variant:before {
  content: "\f44f";
}
.icon-refresh:before {
  content: "\f450";
}
.icon-repeat:before {
  content: "\f456";
}
.icon-repeat-off:before {
  content: "\f457";
}
.icon-repeat-once:before {
  content: "\f458";
}
.icon-rewind:before {
  content: "\f45f";
}
.icon-rss:before {
  content: "\f46b";
}
.icon-script:before {
  content: "\f478";
}
.icon-settings:before {
  content: "\f493";
}
.icon-shield:before {
  content: "\f498";
}
.icon-shuffle:before {
  content: "\f49d";
}
.icon-signal:before {
  content: "\f4a2";
}
.icon-sitemap:before {
  content: "\f4aa";
}
.icon-skip-next:before {
  content: "\f4ad";
}
.icon-skip-previous:before {
  content: "\f4ae";
}
.icon-sleep:before {
  content: "\f4b2";
}
.icon-sleep-off:before {
  content: "\f4b3";
}
.icon-spellcheck:before {
  content: "\f4c6";
}
.icon-star:before {
  content: "\f4ce";
}
.icon-star-half:before {
  content: "\f4d0";
}
.icon-stop:before {
  content: "\f4db";
}
.icon-tab:before {
  content: "\f4e9";
}
.icon-table:before {
  content: "\f4eb";
}
.icon-tag:before {
  content: "\f4f9";
}
.icon-television:before {
  content: "\f502";
}
.icon-thumb-down:before {
  content: "\f511";
}
.icon-thumb-up:before {
  content: "\f513";
}
.icon-thumbs-up-down:before {
  content: "\f515";
}
.icon-tooltip:before {
  content: "\f523";
}
.icon-tooltip-text:before {
  content: "\f528";
}
.icon-triangle:before {
  content: "\f536";
}
.icon-trophy:before {
  content: "\f538";
}
.icon-undo-variant:before {
  content: "\f54d";
}
.icon-unfold-less-horizontal:before {
  content: "\f54e";
}
.icon-unfold-more-horizontal:before {
  content: "\f54f";
}
.icon-ungroup:before {
  content: "\f550";
}
.icon-upload:before {
  content: "\f552";
}
.icon-vector-arrange-above:before {
  content: "\f554";
}
.icon-vector-arrange-below:before {
  content: "\f555";
}
.icon-vector-combine:before {
  content: "\f558";
}
.icon-vector-difference:before {
  content: "\f55a";
}
.icon-vector-difference-ab:before {
  content: "\f55b";
}
.icon-vector-difference-ba:before {
  content: "\f55c";
}
.icon-vector-intersection:before {
  content: "\f55d";
}
.icon-vector-union:before {
  content: "\f564";
}
.icon-view-dashboard:before {
  content: "\f56e";
}
.icon-view-module:before {
  content: "\f573";
}
.icon-volume-high:before {
  content: "\f57e";
}
.icon-volume-off:before {
  content: "\f581";
}
.icon-wifi:before {
  content: "\f5a9";
}
.icon-xml:before {
  content: "\f5c0";
}
.icon-book-plus:before {
  content: "\f5db";
}
.icon-hamburger:before {
  content: "\f684";
}
.icon-inbox:before {
  content: "\f686";
}
.icon-note-multiple:before {
  content: "\f6b7";
}
.icon-heart-half:before {
  content: "\f6de";
}
.icon-lightbulb-on:before {
  content: "\f6e7";
}
.icon-rhombus:before {
  content: "\f70a";
}
.icon-cancel:before {
  content: "\f739";
}
.icon-clipboard-plus:before {
  content: "\f750";
}
.icon-file-plus:before {
  content: "\f751";
}
.icon-format-align-bottom:before {
  content: "\f752";
}
.icon-format-align-middle:before {
  content: "\f753";
}
.icon-format-align-top:before {
  content: "\f754";
}
.icon-format-list-checks:before {
  content: "\f755";
}
.icon-format-quote-open:before {
  content: "\f756";
}
.icon-grid-large:before {
  content: "\f757";
}
.icon-heart-off:before {
  content: "\f758";
}
.icon-music:before {
  content: "\f759";
}
.icon-music-off:before {
  content: "\f75a";
}
.icon-tab-plus:before {
  content: "\f75b";
}
.icon-volume-plus:before {
  content: "\f75c";
}
.icon-volume-minus:before {
  content: "\f75d";
}
.icon-volume-mute:before {
  content: "\f75e";
}
.icon-unfold-less-vertical:before {
  content: "\f75f";
}
.icon-unfold-more-vertical:before {
  content: "\f760";
}
.icon-taco:before {
  content: "\f761";
}
.icon-square:before {
  content: "\f763";
}
.icon-circle:before {
  content: "\f764";
}
.icon-account-book:before {
  content: "\f004";
  font-size: 54%;
  position: absolute;
  top: 42%;
  left: 20%;
}
.icon-account-book:after {
  content: "\f0ba";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
